import styled from "styled-components";

const container = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
`;

export default {
  container,
};
